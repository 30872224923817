import React from "react";

const Hero = () => {
  return (
    <section className="flex items-center justify-center py-10 text-white bg-white sm:py-16 md:py-24 lg:py-24">
      <div className="relative max-w-3xl px-10 text-center text-white auto lg:px-0">
        <div className="flex flex-col w-full md:flex-row">
          {/* Top Text */}
          <div className="flex justify-between">
            <h1 className="relative flex flex-col text-6xl font-extrabold text-left text-black">
              The
              <span className="">Goose</span>
              <span className="">Gustavo</span>
              <span className="">Collection</span>
            </h1>
          </div>
          {/* Right Image  */}
          <div className="relative top-0 right-0 h-64 mt-12 md:-mt-16 md:absolute md:h-96">
            <img
              src="/images/egg.png"
              className="object-cover mt-3 mr-5 h-80 lg:h-80"
            />
          </div>
        </div>

        {/* Separator  */}
        <div className="my-16 border-b border-gray-300 lg:my-16"></div>

        {/* Bottom Text  */}
        <h2 className="text-center text-gray-500 xl:text-xl justify-center">
          The Goose Gustavo Collection is a collection of 1555 NFTs that are
          procedurally generated based on item interaction.
        </h2>
      </div>
    </section>
  );
};

export default Hero;
