import React, { useEffect, useState, useContext } from "react";
import { ethers, BigNumber } from "ethers";
import ReactPlayer from "react-player";

import { ProviderContext } from "./../DataContext";
import { CollectionConfigS2 } from "../contract/CollectionConfig";

const ContractAbiS2 = require("../contract/" +
  CollectionConfigS2.contractName +
  ".sol/" +
  CollectionConfigS2.contractName +
  ".json").abi;

const tokensS2 = [
  {
    id: 0,
    isActive: true,
    name: "#1 Pilot",
    image: "/images/s2/1.mp4",
    price: 0.03,
  },
  {
    id: 1,
    isActive: true,
    name: "#2 Bad Day",
    image: "/images/s2/2.mp4",
    price: 0.03,
  },
  {
    id: 2,
    isActive: true,
    name: "#3 Doppelganger",
    image: "/images/s2/3.mp4",
    price: 0.03,
  },
  {
    id: 3,
    isActive: true,
    name: "#4 I want to believe",
    image: "/images/s2/4.mp4",
    price: 0.03,
  },
  {
    id: 4,
    isActive: true,
    name: "#5 Washing day",
    image: "/images/s2/5.mp4",
    price: 0.03,
  },
  {
    id: 5,
    isActive: true,
    name: "#6 Traveler",
    image: "/images/s2/6.mp4",
    price: 0.03,
  },
];

const MintingBoxS2 = () => {
  const defaultState = {
    userAddress: null,
    network: null,
    s2: {
      totalSupply: 0,
      maxSupply: 0,
      maxMintAmountPerTx: 0,
      tokenPrice: BigNumber.from(0),
      isPaused: true,
    },
    errorMessage: null,
  };

  const { provider, userAddress } = useContext(ProviderContext);

  const [isLoading, setIsLoading] = useState(true);

  // const [network, setNetwork] = useState(defaultState.network);

  const [contractS2, setContractS2] = useState(null);
  const [totalSupplyS2, setTotalSupplyS2] = useState(
    defaultState.s2.totalSupply
  );
  const [maxSupplyS2, setMaxSupplyS2] = useState(defaultState.s2.maxSupply);
  const [tokenPriceS2, setTokenPriceS2] = useState(defaultState.s2.tokenPrice);

  const [errorMessage, setErrorMessage] = useState(defaultState.errorMessage);

  useEffect(() => {
    console.log("effect s2", provider);
    if (!provider) return;

    setIsLoading(false);

    const setup = async () => {
      registerWalletEvents(provider);
      await initWallet();
    };

    setup();
  }, [provider, userAddress]);

  const resetState = () => {
    setTotalSupplyS2(defaultState.s2.totalSupply);
    setMaxSupplyS2(defaultState.s2.maxSupply);
    setTokenPriceS2(defaultState.s2.tokenPrice);

    setErrorMessage(defaultState.errorMessage);
  };

  const setError = (error) => {
    let errorMessage = "Unknown error...";

    if (null === error || typeof error === "string") {
      errorMessage = error;
    } else if (typeof error === "object") {
      // Support any type of error from the Web3 Provider...
      if (error?.error?.message !== undefined) {
        errorMessage = error.error.message;
      } else if (error?.data?.message !== undefined) {
        errorMessage = error.data.message;
      } else if (error?.message !== undefined) {
        errorMessage = error.message;
      }
    }

    setErrorMessage(
      null === errorMessage
        ? null
        : errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1)
    );
  };

  const initWallet = async () => {
    if (!provider) return;

    const walletAccounts = await provider.listAccounts();

    resetState();

    if (walletAccounts.length === 0) {
      return;
    }

    if ((await provider.getCode(CollectionConfigS2.contractAddress)) === "0x") {
      setErrorMessage(
        "Could not find the contract, are you connected to the right chain?"
      );
      return;
    }

    const _contractS2 = new ethers.Contract(
      CollectionConfigS2.contractAddress,
      ContractAbiS2,
      provider.getSigner()
    );

    setContractS2(_contractS2);
    setMaxSupplyS2((await _contractS2.MAX_SUPPLY()).toNumber());
    setTotalSupplyS2((await _contractS2.totalSupply()).toNumber());
    setTokenPriceS2(await _contractS2.cost());
    const arrItemsS2 = [];
    for (var i = 0; i < totalSupplyS2; i++) {
      arrItemsS2.push({
        id: i,
      });
    }
  };

  const registerWalletEvents = (browserProvider) => {
    browserProvider.on("accountsChanged", () => {
      initWallet();
    });

    browserProvider.on("chainChanged", () => {
      window.location.reload();
    });
  };

  const mintTokensS2 = async (id) => {
    try {
      await contractS2.mint(id, {
        value: tokenPriceS2.mul(1),
      });
    } catch (e) {
      e.reason && alert(e.reason);
      setError(e);
    }
  };

  const isWalletConnected = () => {
    return !!userAddress;
  };

  const isContractS2Ready = () => {
    return !!contractS2;
  };

  return (
    <>
      {!isLoading && (
        <section className="py-20">
          <div className="container max-w-6xl mx-auto">
            {isWalletConnected() && (
              <>
                <h2 className="text-4xl font-bold tracking-tight text-center">
                  Goose Gustavo Series 2
                </h2>
                <p className="mt-2 text-lg text-center text-gray-600">
                  Only for the owners of Gustavo
                </p>
                <div className="grid grid-cols-4 gap-8 mt-10 sm:grid-cols-8 lg:grid-cols-12 sm:px-8 xl:px-0 ">
                  {isContractS2Ready() &&
                    tokensS2.map((item, index) => (
                      <div
                        key={index}
                        className="relative flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 overflow-hidden bg-zinc-200 sm:rounded-xl"
                      >
                        <div className="preview sm:rounded-xl">
                          <ReactPlayer
                            url={item.image}
                            controls={true}
                            playing={true}
                            loop={true}
                            muted={true}
                            width={275}
                            height={275}
                          />
                        </div>

                        <div>
                          <strong>{item.name}</strong>
                        </div>

                        <div className="price">
                          Price:{" "}
                          {item.price ? (
                            <span>{item.price} ETH</span>
                          ) : (
                            <span>TBD</span>
                          )}
                        </div>

                        <div className="controls">
                          {item.isActive && (
                            <button
                              className="primary"
                              style={{ borderRadius: 0 }}
                              disabled={!item.isActive && "disabled"}
                              onClick={() => mintTokensS2(item.id)}
                            >
                              Mint
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default MintingBoxS2;
