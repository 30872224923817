import React from "react";

const CollectionStatus = (props) => {
  const isSaleOpen = () => {
    return !props.isPaused;
  };

  return (
    <>
      <div className="collection-status">
        <div className="user-address">
          <span className="label">Wallet address:</span>
          <span className="address">{props.userAddress}</span>
        </div>

        <div className="supply">
          <span className="label">Supply</span>
          {props.totalSupply}/{props.maxSupply}
        </div>

        <div className="current-sale">
          <span className="label">Sale status</span>
          {isSaleOpen() ? "Open" : "Closed"}
        </div>
      </div>
    </>
  );
};

export default CollectionStatus;
