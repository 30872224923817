import React, { useState } from "react";
import { utils } from "ethers";

const MintWidget = (props) => {
  const defaultState = {
    mintAmount: 1,
  };

  const [mintAmount, setMintAmount] = useState(defaultState.mintAmount);

  const canMint = () => {
    return !props.isPaused;
  };

  const incrementMintAmount = () => {
    setMintAmount(Math.min(props.maxMintAmountPerTx, mintAmount + 1));
  };

  const decrementMintAmount = () => {
    setMintAmount(Math.max(1, mintAmount - 1));
  };

  const mint = async () => {
    if (!props.isPaused) {
      await props.mintTokens(mintAmount);

      return;
    }
  };

  return (
    <>
      {canMint() ? (
        <div className="mint-widget">
          <div className="preview">
            <img src="/images/egg_small.png" alt="Collection preview" />
          </div>

          <div className="price">
            <strong>Total price:</strong>{" "}
            {props.tokenPrice &&
              utils.formatEther(props.tokenPrice.mul(mintAmount))}{" "}
            ETH
          </div>

          <div className="controls">
            <button className="decrease" onClick={() => decrementMintAmount()}>
              -
            </button>
            <span className="mint-amount">{mintAmount}</span>
            <button className="increase" onClick={() => incrementMintAmount()}>
              +
            </button>
            <button className="primary" onClick={() => mint()}>
              Mint
            </button>
          </div>
        </div>
      ) : (
        <div className="cannot-mint">
          <span className="emoji">⏳</span>
          <>
            The contract is <strong>paused</strong>.
          </>
          <br />
          Please come back during the next sale!
        </div>
      )}
    </>
  );
};

export default MintWidget;
