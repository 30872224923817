export const CollectionConfig = {
  contractName: "GustavoNftToken",
  tokenName: "Gustavo NFT",
  tokenSymbol: "GUST",
  hiddenMetadataUri:
    "ipfs://bafybeidxn4ykeygyr7fxmq42mwfljhykhdvmkpoisehkoxllg4q4v4hs2m/hidden.json",
  maxSupply: 1555,
  whitelistSale: {
    price: 0.01,
    maxMintAmountPerTx: 1,
  },
  preSale: {
    price: 0.01,
    maxMintAmountPerTx: 2,
  },
  publicSale: {
    price: 0.01,
    maxMintAmountPerTx: 2,
  },
  contractAddress: "0x05b319F57F93abaDD226C723bf06046a7A6fa163",
  openSeaSlug: "gustavo-nft-token-v2",
  whitelistAddresses: [],
};

export const CollectionConfigS2 = {
  contractName: "GustavoS2",
  tokenName: "Gustavo Series 2",
  tokenSymbol: "GUSTS2",
  maxSupply: 1555,
  contractAddress: "0x0679BcA633418527D886b0Eb90E6cE563aF7910b",
  openSeaSlug: "gustavo-series-2",
};
