import "./App.css";

import React, { useState, useEffect } from "react";
import detectEthereumProvider from "@metamask/detect-provider";
import { ethers } from "ethers";

import { ProviderContext } from "./DataContext";
import Header from "./components/header.component";
import MintingBox from "./components/minting.component";
import MintingBoxS2 from "./components/minting-s2.component";
import Footer from "./components/footer.component";
import Hero from "./components/hero.component";
import Roadmap from "./components/roadmap.component";
import Faq from "./components/faq.component";
import How from "./components/how.component";

function App() {
  const [provider, setProvider] = useState(undefined);
  const [noMetamaskError, setNoMetamaskError] = useState(false);

  const [userAddress, setUserAddress] = useState(undefined);

  useEffect(() => {
    const setup = async () => {
      const browserProvider = await detectEthereumProvider();
      if (browserProvider?.isMetaMask !== true) {
        setNoMetamaskError(true);
      } else {
        setProvider(new ethers.providers.Web3Provider(browserProvider));
      }
    };
    setup();
  }, []);

  return (
    <ProviderContext.Provider
      value={{ provider, noMetamaskError, userAddress, setUserAddress }}
    >
      <div className="App">
        <Header />
        <Hero />
        <MintingBox />
        <How />
        <MintingBoxS2 />
        <Roadmap />
        <Faq />
        <Footer />
      </div>
    </ProviderContext.Provider>
  );
}

export default App;
