import React from "react";

import { FaTwitter, FaInstagram, FaFacebook, FaStore } from "react-icons/fa";

const style = {
  link: "text-base leading-6 text-white hover:text-gray-200",
  iconLink: "text-white hover:text-gray-200 text-3xl",
};

const Footer = () => {
  return (
    <section className="bg-emerald-400">
      <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
        {/* <nav className="flex flex-wrap justify-center -mx-5 -my-2">
          <div className="px-5 py-2">
            <a href="/" className={style.link}>
              About
            </a>
          </div>
          <div className="px-5 py-2">
            <a href="/" className={style.link}>
              Roadmap
            </a>
          </div>
          <div className="px-5 py-2">
            <a href="/" className={style.link}>
              Team
            </a>
          </div>
        </nav> */}

        <div className="flex justify-center mt-8 space-x-6">
          <a
            href="https://www.instagram.com/gustavogoosenft/"
            className={style.iconLink}
            target="_blank"
            rel="noreferrer"
          >
            <span className="sr-only">Instagram</span>
            <FaInstagram />
          </a>
          <a
            href="https://twitter.com/gustavogoosenft"
            className={style.iconLink}
            target="_blank"
            rel="noreferrer"
          >
            <span className="sr-only">Twitter</span>
            <span className="w-6 h-6">
              <FaTwitter />
            </span>
          </a>
          <a
            href="https://opensea.io/collection/gustavo-nft-v2"
            className={style.iconLink}
            target="_blank"
            rel="noreferrer"
          >
            <span className="sr-only">OpenSea</span>
            <span className="w-6 h-6">
              <FaStore />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Footer;
