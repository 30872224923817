import React from "react";

const Roadmap = () => {
  return (
    <section className="relative py-16 bg-zinc-200 min-w-screen animation-fade animation-delay">
      <div className="container px-0 mx-auto sm:px-12 xl:px-5">
        <h3 className="mt-1 text-2xl font-bold text-left text-gray-800 sm:mx-6 sm:text-3xl md:text-4xl lg:text-5xl sm:text-center sm:mx-0">
          Roadmap
        </h3>

        <div className="w-full px-6 py-6 mx-auto mt-10 sm:px-8 md:px-12 sm:py-8 lg:w-5/6 xl:w-2/3">
          <h3 className="text-lg font-bold  sm:text-xl md:text-2xl">Phase 1</h3>
          <p className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
            Done👍 Launch Gustavo mint.
          </p>
        </div>

        <div className="w-full px-6 py-6 mx-auto mt-10 sm:px-8 md:px-12 sm:py-8 lg:w-5/6 xl:w-2/3">
          <h3 className="text-lg font-bold  sm:text-xl md:text-2xl">Phase 2</h3>
          <p className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
            Done👍 Hatching ALL eggs.
          </p>
        </div>

        <div className="w-full px-6 py-6 mx-auto mt-10 sm:px-8 md:px-12 sm:py-8 lg:w-5/6 xl:w-2/3">
          <h3 className="text-lg font-bold  sm:text-xl md:text-2xl">Phase 3</h3>
          <p className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
            Done👍 Reveal ALL Gustavo's.
          </p>
        </div>

        <div className="w-full px-6 py-6 mx-auto mt-10 sm:px-8 md:px-12 sm:py-8 lg:w-5/6 xl:w-2/3">
          <h3 className="text-lg font-bold  sm:text-xl md:text-2xl">Phase 4</h3>
          <p className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
            Done👍 Launch our new collection mint!
            <br />
            <i>
              The new collection is out. Owners of Gustavo will get access to it
              at first.
            </i>
          </p>
        </div>

        <div className="w-full px-6 py-6 mx-auto mt-10 sm:px-8 md:px-12 sm:py-8 lg:w-5/6 xl:w-2/3">
          <h3 className="text-lg font-bold  sm:text-xl md:text-2xl">Phase 5</h3>
          <p className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
            In progress👷 We have exciting news for you.😊 We are working on a
            new tool that can help you evaluate the market and make good
            decisions.🔥 Be the first who can catch trending projects, analyze
            tokens and wallets, and watch for the smartest (rich) users on the
            chain.👀 Stay tuned!
          </p>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
