import React from "react";

const How = () => {
  return (
    <section className="py-20 bg-zinc-200">
      <div className="container max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold tracking-tight text-center">
          How to get it?
        </h2>
        <p className="mt-2 text-lg text-center text-gray-600">
          That is so simple.
        </p>
        <div className="grid grid-cols-4 gap-8 mt-10 sm:grid-cols-8 lg:grid-cols-12 sm:px-8 xl:px-0 ">
          <div className="relative flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 overflow-hidden bg-white sm:rounded-xl">
            <div className="p-3">
              <img src="/images/egg_small.png" alt="" />
            </div>
            <h4 className="text-xl font-medium text-gray-700">Buy an Egg</h4>
            {/* <p className="text-base text-center text-gray-500">Website.</p> */}
          </div>

          <div className="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-white sm:rounded-xl">
            <div className="p-3 ">
              <img src="/images/cracking_egg.gif" alt="" />
            </div>
            <h4 className="text-xl font-medium text-gray-700">Wait</h4>
            {/* <p className="text-base text-center text-gray-500">
              Send out notifications to all your customers to keep them engaged.
            </p> */}
          </div>

          <div className="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-white sm:rounded-xl">
            <div className="p-3 ">
              <img src="/images/gustavo_show.gif" alt="" />
            </div>
            <h4 className="text-xl font-medium text-gray-700">Get a Gustavo</h4>
            {/* <p className="text-base text-center text-gray-500">
              Send out notifications to all your customers to keep them engaged.
            </p> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default How;
