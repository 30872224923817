import React from "react";

const Faq = () => {
  return (
    <section className="relative py-16 bg-white min-w-screen animation-fade animation-delay">
      <div className="container px-0 mx-auto sm:px-12 xl:px-5">
        {/* <p className="text-xs font-bold text-left text-pink-500 uppercase sm:mx-6 sm:text-center sm:text-normal sm:font-bold">
          Got a Question? We’ve got answers.
        </p> */}
        <h3 className="mt-1 text-2xl font-bold text-left text-gray-800 sm:mx-6 sm:text-3xl md:text-4xl lg:text-5xl sm:text-center sm:mx-0">
          Frequently Asked Questions
        </h3>
        <div className="w-full px-6 py-6 mx-auto mt-10  border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
          <h3 className="text-lg font-bold  sm:text-xl md:text-2xl">
            What is NFT?
          </h3>
          <p className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
            NFT stands for &quot;Non-fungible token&quot; and is a cool way of
            saying it&#39;s a truly unique digital item that you can buy, own,
            and trade.
          </p>
        </div>
        <div className="w-full px-6 py-6 mx-auto mt-10  border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
          <h3 className="text-lg font-bold  sm:text-xl md:text-2xl">
            What is Metamask?
          </h3>
          <p className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
            Metamask is a crypto-wallet that can store your Ethereum, and is
            needed to purchase and mint a Bored Weird Vitalik. Having a wallet
            gives you an Ethereum address (i.e. 0xaC2x….000), this is where your
            NFT will be stored.
          </p>
        </div>
        <div className="w-full px-6 py-6 mx-auto mt-10 border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
          <h3 className="text-lg font-bold  sm:text-xl md:text-2xl">
            When is the sale open and how much will it cost?
          </h3>
          <p className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
            The sale will be announced very soon! Each Gustavo will cost 0.005
            ETH.
          </p>
        </div>
        <div className="w-full px-6 py-6 mx-auto mt-10 border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
          <h3 className="text-lg font-bold  sm:text-xl md:text-2xl">
            Buying NFT for the first time?
          </h3>
          <p className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
            Get the Metamask Chrome or Firefox extension. Load it with ETH
            through services that allow you to change your money to ETH like
            Coinbase, Binance or Kraken. Then, click the button &quot;MINT&quot;
            on the top banner to connect your wallet and approve the transaction
            on Metamask. That&#39;s it!
          </p>
        </div>
        <div className="w-full px-6 py-6 mx-auto mt-10  border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
          <h3 className="text-lg font-bold  sm:text-xl md:text-2xl">
            Where can I see my Gustavo NFT after purchase?
          </h3>
          <p className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
            Your Gustavo NFT will appear in metamask wallet. You can also see
            your freshly minted NFT art directly on your Opensea.io and Rarible
            accounts.
          </p>
        </div>
        <div className="w-full px-6 py-6 mx-auto mt-10  border border-gray-200 rounded-lg sm:px-8 md:px-12 sm:py-8 sm:shadow lg:w-5/6 xl:w-2/3">
          <h3 className="text-lg font-bold  sm:text-xl md:text-2xl">
            What can I do with my Gustavo / How can I trade it?
          </h3>
          <p className="mt-2 text-base text-gray-600 sm:text-lg md:text-normal">
            You are free to do anything with them under a non-exclusive license.
            Gustavo adheres to the ERC-721 standard so you can trade them on
            platforms like OpenSea, Rarible etc.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Faq;
