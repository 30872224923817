import React from "react";

// const style = {
//   link: "inline-block w-full py-2 mx-0 ml-6 leading-6 text-left text-white md:ml-0 md:w-auto md:px-0 md:mx-2 lg:mx-3 md:text-center",
// };

const Header = () => {
  return (
    <section className="relative w-full px-8  bg-blue-400 body-font">
      <div className="container flex flex-col flex-wrap items-center justify-between py-5 mx-auto md:flex-row max-w-7xl">
        <div className="">
          <a
            href="#_"
            className="relative z-10 flex items-center w-auto text-2xl font-extrabold leading-none text-black select-none"
          >
            <img src="/images/logo.png" alt="" width={60} />
          </a>
        </div>

        <nav className="top-0 left-0 z-0 flex items-center justify-center w-full h-full py-5 -ml-0 space-x-5 text-base md:-ml-5 md:py-0 md:absolute">
          <a
            href="https://www.instagram.com/gustavogoosenft/"
            className="relative font-medium leading-6 text-white transition duration-150 ease-out "
            x-data="{ hover: false }"
            target="_blank"
            rel="noreferrer"
          >
            <span className="block">Instagram</span>
          </a>
          <a
            href="https://twitter.com/gustavogoosenft"
            className="relative font-medium leading-6 text-white transition duration-150 ease-out "
            x-data="{ hover: false }"
            target="_blank"
            rel="noreferrer"
          >
            <span className="block">Twitter</span>
          </a>
          <a
            href="https://opensea.io/collection/gustavo-nft-v2"
            className="relative font-medium leading-6 text-white transition duration-150 ease-out "
            x-data="{ hover: false }"
            target="_blank"
            rel="noreferrer"
          >
            <span className="block">Opensea</span>
          </a>
        </nav>
      </div>
    </section>
  );
};

export default Header;
