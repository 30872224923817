import React, { useContext, useEffect, useState } from "react";
import { ethers, BigNumber } from "ethers";

import { ProviderContext } from "./../DataContext";
import MintWidget from "./mint-widget.component";
import CollectionStatus from "./collection-status.component";
import { CollectionConfig } from "../contract/CollectionConfig";

const ContractAbi = require("../contract/" +
  CollectionConfig.contractName +
  ".sol/" +
  CollectionConfig.contractName +
  ".json").abi;

const MintingBox = () => {
  const defaultState = {
    userAddress: null,
    network: null,
    s1: {
      totalSupply: 0,
      maxSupply: 0,
      maxMintAmountPerTx: 0,
      tokenPrice: BigNumber.from(0),
      isPaused: true,
    },

    errorMessage: null,
  };

  const { provider, noMetamaskError, userAddress, setUserAddress } =
    useContext(ProviderContext);

  const [isLoading, setIsLoading] = useState(true);

  const [network, setNetwork] = useState(defaultState.network);

  const [contract, setContract] = useState(null);
  const [totalSupply, setTotalSupply] = useState(defaultState.s1.totalSupply);
  const [maxSupply, setMaxSupply] = useState(defaultState.s1.maxSupply);
  const [maxMintAmountPerTx, setMaxMintAmountPerTx] = useState(
    defaultState.s1.maxMintAmountPerTx
  );
  const [tokenPrice, setTokenPrice] = useState(defaultState.s1.tokenPrice);
  const [isPaused, setIsPaused] = useState(defaultState.s1.isPaused);

  const [errorMessage, setErrorMessage] = useState(defaultState.errorMessage);

  useEffect(() => {
    if (!provider) return;

    if (noMetamaskError) {
      setErrorMessage(
        <>
          MetaMask is not detected. Please install it from{" "}
          <a href="https://metamask.io/" target="_blank" rel="noreferrer">
            <u>https://metamask.io/</u>
          </a>
        </>
      );
    }

    setIsLoading(false);

    const setup = async () => {
      registerWalletEvents(provider);
      await initWallet();
    };

    setup();
  }, [provider, noMetamaskError]);

  const resetState = () => {
    setUserAddress(defaultState.userAddress);
    setNetwork(defaultState.network);

    setTotalSupply(defaultState.totalSupply);
    setMaxSupply(defaultState.maxSupply);
    setMaxMintAmountPerTx(defaultState.maxMintAmountPerTx);
    setTokenPrice(defaultState.tokenPrice);
    setIsPaused(defaultState.isPaused);

    setErrorMessage(defaultState.errorMessage);
  };

  const setError = (error) => {
    let errorMessage = "Unknown error...";

    if (null === error || typeof error === "string") {
      errorMessage = error;
    } else if (typeof error === "object") {
      // Support any type of error from the Web3 Provider...
      if (error?.error?.message !== undefined) {
        errorMessage = error.error.message;
      } else if (error?.data?.message !== undefined) {
        errorMessage = error.data.message;
      } else if (error?.message !== undefined) {
        errorMessage = error.message;
      }
    }

    setErrorMessage(
      null === errorMessage
        ? null
        : errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1)
    );
  };

  const generateOpenSeaUrl = () => {
    const subdomain = network?.chainId === 1 ? "www" : "testnets";

    return (
      `https://${subdomain}.opensea.io/` +
      (CollectionConfig.openSeaSlug
        ? "collection/" + CollectionConfig.openSeaSlug
        : null)
    );
  };

  const connectWallet = async () => {
    try {
      await provider.provider.request({ method: "eth_requestAccounts" });

      initWallet();
    } catch (e) {
      setError(e);
    }
  };

  const initWallet = async () => {
    if (!provider) return;

    const walletAccounts = await provider.listAccounts();

    resetState();

    if (walletAccounts.length === 0) {
      return;
    }

    setUserAddress(walletAccounts[0]);
    setNetwork(await provider.getNetwork());

    if ((await provider.getCode(CollectionConfig.contractAddress)) === "0x") {
      setErrorMessage(
        "Could not find the contract, are you connected to the right chain?"
      );
      return;
    }

    const _contract = new ethers.Contract(
      CollectionConfig.contractAddress,
      ContractAbi,
      provider.getSigner()
    );

    setContract(_contract);
    setMaxSupply((await _contract.maxSupply()).toNumber());
    setTotalSupply((await _contract.totalSupply()).toNumber());
    setMaxMintAmountPerTx((await _contract.maxMintAmountPerTx()).toNumber());
    setTokenPrice(await _contract.cost());
    setIsPaused(await _contract.paused());
  };

  const registerWalletEvents = (browserProvider) => {
    browserProvider.on("accountsChanged", () => {
      console.log("accountsChanged s1");
      initWallet();
    });

    browserProvider.on("chainChanged", () => {
      window.location.reload();
    });
  };

  const mintTokensS1 = async (amount) => {
    try {
      await contract.mint(amount, {
        value: tokenPrice.mul(amount),
      });
    } catch (e) {
      setError(e);
    }
  };

  const isNotMainnet = () => {
    return network && network.chainId !== 1;
  };

  const isWalletConnected = () => {
    return !!userAddress;
  };

  const isSoldOutS1 = () => {
    return maxSupply !== 0 && totalSupply < maxSupply;
  };

  const isContractS1Ready = () => {
    return !!contract;
  };

  return (
    <>
      {!isLoading && (
        <>
          <section className="flex items-center justify-center pb-10 sm:pb-16 md:pb-24 lg:pb-24">
            <div className="relative max-w-3xl px-10 text-center  auto lg:px-0">
              <div id="minting-dapp">
                {isNotMainnet() ? (
                  <div className="not-mainnet text-red-500">
                    You are not connected to the main network.{" "}
                    <span className="small">
                      Current network: <strong>{network?.name}</strong>
                    </span>
                  </div>
                ) : null}

                {errorMessage && (
                  <p className="mt-2 text-lg text-center text-red-500">
                    {errorMessage}
                  </p>
                )}

                {isWalletConnected() && (
                  <>
                    {isContractS1Ready() && (
                      <>
                        <CollectionStatus
                          userAddress={userAddress}
                          maxSupply={maxSupply}
                          totalSupply={totalSupply}
                          isPaused={isPaused}
                        />
                        {totalSupply < maxSupply ? (
                          <MintWidget
                            maxSupply={maxSupply}
                            totalSupply={totalSupply}
                            tokenPrice={tokenPrice}
                            maxMintAmountPerTx={maxMintAmountPerTx}
                            isPaused={isPaused}
                            mintTokens={(mintAmount) =>
                              mintTokensS1(mintAmount)
                            }
                          />
                        ) : (
                          <div className="collection-sold-out">
                            <h2>
                              Tokens have been <strong>sold out</strong>!{" "}
                              <span className="emoji">🥳</span>
                            </h2>
                            Now you can buy them from their holders on{" "}
                            <a
                              href={generateOpenSeaUrl()}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <u>OpenSea</u>
                            </a>
                            .
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}

                {!isWalletConnected() || !isSoldOutS1() ? (
                  <div className="no-wallet">
                    {!isWalletConnected() && (
                      <button
                        className="px-6 py-3 mb-3 text-lg text-white bg-blue-400 rounded-md sm:mb-0 hover:bg-blue-500 sm:w-auto"
                        disabled={!provider}
                        onClick={() => connectWallet()}
                      >
                        Connect Wallet
                      </button>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default MintingBox;
